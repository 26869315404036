import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import PulseLoader from "react-spinners/PulseLoader";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import {
  HttpMainApi,
  MakeGptContent,
  GetHistoryInfoParam,
} from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { useInterval } from "../../utils/UseInterval";
import { appConfig } from "../../config/Config";

import Copy from "../reaction/Copy";
import Like from "../reaction/Like";
import UnLike from "../reaction/UnLike";

import "./CopyWriter.css";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

interface propsType {
  userState: userState;
  idx: string;
  dataText: string;
  joinText: string;
  finishProc: any;
}

const mainApi = new HttpMainApi();

const CopyResult = (props: propsType, ref: any) => {
  const swiperRef = useRef<any>(null);
  const [pk, setPk] = useState("");
  const [range, setRange] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [toolTipShow, setToolTipShow] = useState(false);

  const [title, setTitle] = useState("");

  const [historyInfo, setHistoryInfo] = useState<any>([]);
  const [slideToNum, setSlideToNum] = useState(0);

  const [pollingTitle, setPollingTitle] = useState(false);
  const [pollingContents, setPollingContents] = useState(false);

  const [viewStatus, setViewStatus] = useState(false); // 결과 보여주기용 핸들러

  const makeTitle = async () => {
    const param: MakeGptContent = {
      type: "title",
      idx: props.idx,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: "",
      pk: "",
      range: "",
    };

    const res = await mainApi.make_gpt_copy(param);
    if (res.code === "200") {
      console.log("Title Success");
      setTitle(res.response.result);
      makeContents(res.response.pk, res.response.range, res.response.result);
    } else {
      console.error("Title Error : ", res);
      if (res.response.error_msg === "apiTimeout") {
        setPollingTitle(true);
        setTimeout(() => {
          if (pollingTitle) {
            setPollingTitle(false);
            props.finishProc(props.idx, "fail");
          }
        }, 120000);
      } else {
        props.finishProc(props.idx, "fail");
      }
    }
  };

  const makeContents = async (
    pk: string,
    range: string,
    titleResult: string
  ) => {
    const param: MakeGptContent = {
      type: "contents",
      idx: props.idx,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: titleResult,
      pk: pk,
      range: range,
    };

    const res = await mainApi.make_gpt_copy(param);
    if (res.code === "200") {
      console.log("Content Success");
      setPk(res.response.pk);
      setRange(res.response.range);
      props.finishProc(props.idx, "success");
    } else {
      console.error("Content Error : ", res);
      if (res.response.error_msg === "apiTimeout") {
        setPollingContents(true);
        setTimeout(() => {
          if (pollingContents) {
            setPollingContents(false);
            props.finishProc(props.idx, "fail");
          }
        }, 120000);
      } else {
        props.finishProc(props.idx, "fail");
      }
    }
  };

  useInterval(
    async () => {
      console.log("Title polling : ", props.dataText, "--", props.joinText);
      const result = await pollingTitleInfo();
    },
    pollingTitle ? 3000 : null
  );

  useInterval(
    async () => {
      console.log("Contents polling : ", props.dataText, "--", props.joinText);
      const result = await pollingContentsInfo();
      if (result) setPollingContents(false);
    },
    pollingContents ? 3000 : null
  );

  const pollingTitleInfo = async () => {
    const param: MakeGptContent = {
      type: "title",
      idx: props.idx,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: "",
      pk: "",
      range: "",
    };

    const res = await mainApi.poll_gpt_info(param);
    if ("" + res.code === "200") {
      setTitle(res.response.result);
      makeContents(res.response.pk, res.response.range, res.response.result);
      setPollingTitle(false);
    } else {
      console.error("pollingTitleInfo ERROR : ", res.response.error_msg);
    }
  };

  const pollingContentsInfo = async () => {
    const param: MakeGptContent = {
      type: "contents",
      idx: props.idx,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: "",
      pk: "",
      range: "",
    };

    const res = await mainApi.poll_gpt_info(param);
    if ("" + res.code === "200") {
      setPk(res.response.pk);
      setRange(res.response.range);
      props.finishProc(props.idx, "success");
      setPollingContents(false);
      return true;
    } else {
      console.error("pollingContentsInfo ERROR : ", res.response.error_msg);
      return false;
    }
  };

  // 카피 만들기 (본문 추가생성)
  const makeContentsMore = async () => {
    if (isLoading) return;

    const param: MakeGptContent = {
      type: "contents",
      idx: props.idx,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: title,
      pk: pk,
      range: range,
    };
    setIsLoading(true);
    setToolTipShow(false);
    const res = await mainApi.make_gpt_copy(param);
    if ("" + res.code === "200") {
      get_history_info();
    } else {
      // setLoading(false);
      console.error("makeContentsMore ERROR : ", res.response.error_msg);
    }
    setIsLoading(false);
  };

  // 결과조회
  const get_history_info = async () => {
    if (slideToNum % 3 === 0) {
      setToolTipShow(true);
    } else {
      setToolTipShow(false);
    }

    const param: GetHistoryInfoParam = {
      pk: pk,
      range: range,
    };

    const res = await mainApi.get_history_info(param);
    if ("" + res.code === "200") {
      setHistoryInfo(res.response.data_info);
      setSlideToNum(res.response.data_info[0][2].length);
    } else {
      console.error("get_history_info ERROR : ", res.response.error_msg);
    }
  };

  // 본문 결과가 2건 이상시 마지막으로 이동
  useEffect(() => {
    if (historyInfo.length > 0) {
      swiperRef.current?.swiper.slideTo(historyInfo[0][2].length);
    }
  }, [historyInfo]);

  // 슬라이더 변경 및 결과 노출 여부에 따라서 결과 조회
  useEffect(() => {
    if (pk !== "" && range !== "" && viewStatus) get_history_info();
  }, [pk, range, viewStatus, slideToNum]); //[] 호출시마다 조회

  // 결과 form 보여주기/감추기 핸들러(부모창에서 호출)
  const changeViewStatus = (flag: boolean) => {
    setViewStatus(flag);
  };
  const [copyContent, setCopyContent] = useState("제목 복사");
  const titleCopy = useCallback(async (text: string) => {
    try {
      setCopyContent("복사 완료");
      setTimeout(() => {
        setCopyContent("문장 복사");
      }, 2000);
      await navigator.clipboard.writeText(text);
    } catch (error) {}
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    makeTitle,
    changeViewStatus,
  }));

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: "1rem",
        display: "flex",
        position: "relative",
        ...(!viewStatus && { display: "none" }),
      }}
      elevation={3}
    >
      {historyInfo.map((item: any, index: number) => (
        <div className="result-wrapper" key={index}>
          <div
            className={
              props.idx === "1"
                ? "type-notice bg-orange"
                : "type-notice bg-green"
            }
          >
            {props.idx === "1" ? "카피A" : "카피B"}
          </div>
          <Stack direction="column" spacing={2.5} sx={{ pt: 2 }}>
            <Tooltip
              title={copyContent}
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                    fontSize: "0.8rem",
                  },
                },
              }}
              arrow
            >
              <Typography
                component="span"
                className="result-title"
                onClick={() => {
                  titleCopy(item[1][0].title);
                }}
              >
                {isLoading ? <Skeleton /> : item[1][0].title}
              </Typography>
            </Tooltip>
            <Typography component="span" className="result-content">
              {isLoading ? <Skeleton /> : item[2][0].content}
            </Typography>

            <Grid container>
              <Grid item xs={6}>
                <Stack direction="row" spacing={0} className="actions">
                  <Copy
                    userState={props.userState}
                    copyText={item[2][0].content}
                  />
                  <Like
                    pk={item[0].pk}
                    range={item[0].range}
                    category={"main"}
                    subRange={item[0].range}
                    userState={props.userState}
                    inputList={item[0].input_list}
                  />
                  <UnLike
                    pk={item[0].pk}
                    range={item[0].range}
                    category={"main"}
                    subRange={item[0].range}
                    userState={props.userState}
                    inputList={item[0].input_list}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Tooltip
                  title={"키워드를 바꾸면 다양한 문장이 만들어집니다."}
                  placement="left"
                  open={toolTipShow}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                          color: "common.black",
                        },
                        fontSize: "0.8rem",
                      },
                    },
                  }}
                  arrow
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className="btn-make-more"
                    onClick={makeContentsMore}
                  >
                    {isLoading ? (
                      <PulseLoader
                        color="#FF6C58"
                        cssOverride={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                        size={9}
                      />
                    ) : (
                      "본문추가생성"
                    )}
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <div className="swiper mySwiper result-list">
              <div>
                <Swiper
                  ref={swiperRef}
                  id="swiper"
                  tag="section"
                  loop={false}
                  navigation={true}
                  pagination={{ type: "fraction" }}
                  slidesPerView={1}
                  onSlideChange={(swiper) => {}}
                >
                  {item[2].map((contentList: any, index: number) => (
                    <SwiperSlide key={contentList.content_no}>
                      <div className="swiper-slide">
                        <div className="slide-cont">
                          <dl>
                            <dd>{contentList.content}</dd>
                          </dl>
                          <div className="slide-icon-wrap">
                            <Copy
                              userState={props.userState}
                              copyText={contentList.content}
                            />
                            <Like
                              pk={item[0].pk}
                              range={item[0].range}
                              category={"content"}
                              subRange={item[0].range}
                              userState={props.userState}
                              inputList={item[0].input_list}
                            />
                            <UnLike
                              pk={item[0].pk}
                              range={item[0].range}
                              category={"content"}
                              subRange={item[0].range}
                              userState={props.userState}
                              inputList={item[0].input_list}
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </Stack>
        </div>
      ))}
    </Paper>
  );
};

export default forwardRef(CopyResult);
