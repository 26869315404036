import Stack from "@mui/material/Stack";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { appConfig } from "../../config/Config";

import "./CopyWriter.css";

const NotiProduct = () => {
  return (
    <>
      <Stack direction="row">
        <ErrorIcon className="copy-info-caution-icon" />
        <Stack direction="column">
          <Typography color="inherit" className="copy-info-caution">
            카피를 만들때 중요한 키워드들을 " , "로 구분하여 나열해서 입력하세요.
          </Typography>
          <Typography color="inherit" className="copy-info-caution-more">
            (ex.제품: 버버리, 코트 / 키워드 : 가을, 면세점, 분위기... )
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row">
        <ErrorIcon className="copy-info-caution-icon" />
        <Stack direction="column">
          <Typography color="inherit" className="copy-info-caution">
            {appConfig.systemName}의 제안은 사실과 다를 수 있으니 꼭 확인해주세요
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default NotiProduct;
