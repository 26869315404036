export const appConfig = {
  projectName: "lewisdemo",
  systemNameEng: "Lewis",
  systemName: "루이스",
  systemNameExam: "루이스가",
  mainApiUrl:
    "https://vr4qnqidz9.execute-api.ap-northeast-2.amazonaws.com/demo-v2/adminMain",
  testApiUrl:
    "https://vr4qnqidz9.execute-api.ap-northeast-2.amazonaws.com/demo-v2/adminMain",
  sessionName: "lewisv2demoadminss", // 사이트마다 달라야 함
  rememberidSSName: "lwsdemov2rmbrid", // 사이트마다 달라야 함
  showTerms: true, // true : 사용자로그인, false : H-ART 로그인
  seed: "2233",
  sessionTimeout: 1000 * 60 * 60 * 3, // 180분
  qnaInfoSSName: "lewisDemov2QnaInfo",
};
